<template>

    <div v-if="view === 'mobile'" class="h-32 w-full p-2 float-left">
        <div class="h-full w-full rounded-lg bg-box flex flex-col overflow-hidden shadow-md">
            <div class="h-6 bg-warning-30 flex flex-row justify-between items-center px-4 flex-none">
                <span class="text-warning font-semibold text-xs">EY Podcast</span>
                <span class="text-xxs text-black">{{ data.date | moment('DD/MM/YYYY') }}</span>
            </div>
            <div class="flex-1 flex flex-col justify-center items-center relative px-2">
                <div class="h-6 w-full flex flex-row mt-4 justify-center items-center py-2">
                    <span class="font-semibold text-sm" style="color: #B4BCCE">{{ data.title }}</span>
                </div>
                <div class="flex-1 w-full flex flex-row justify-center items-center py-2">
                    <i class="mdi mdi-stop text-sm cursor-pointer text-font-gray mr-2" @click="stop()"></i>
                    <i class="text-md cursor-pointer mdi text-font-gray" :class="{'mdi-pause': playmode, 'mdi-play': !playmode}" style="transition: all 1s" @click="play()"></i>
                    <i class="mdi text-sm cursor-pointer text-font-gray ml-2" :class="{'mdi-volume-high': volumemode, 'mdi-volume-off': !volumemode}" @click="mute()"></i>
                </div>
                <div class="h-auto w-full mt-auto flex flex-row justify-end items-center py-2 px-4">
                    <i class="mdi mdi-thumb-up-outline text-font-gray mr-2 cursor-pointer text-xxs" :class="melike(data.likes)" @click="dolike(data.id)"></i>
                    <span class="text-xxs">{{ data.likes.length }}</span>
                </div>
                <audio :id="audioId" :src="fileRoute + data.file" controls class="hidden"></audio>
            </div>
        </div>
    </div>
    <div v-else class="h-48 w-96 p-2 float-left">
        <div class="h-full w-full rounded-lg bg-box flex flex-col overflow-hidden shadow-md">
            <div class="h-12 bg-warning-30 flex flex-row justify-between items-center px-4 flex-none">
                <span class="text-warning font-semibold">EY Podcast</span>
                <span class="text-xs text-black">{{ data.date | moment('DD/MM/YYYY') }}</span>
            </div>
            <div class="flex-1 flex flex-col justify-center items-center relative px-2">
                <div class="h-12 w-full flex mt-4 flex-row justify-center items-center py-2">
                    <span class="font-semibold text-lg mb-2 text-center truncate-3" style="color: #B4BCCE">{{ data.title }}</span>
                </div>
                <span class="flex flex-direction-row space-x-3">
                    <span v-if="data.file && !isAudio(data.file) && data.file.length > 0" class="font-semibold text-font-gray text-md cursor-pointer mt-1"
                          @click="openFile(data.file, 'file')" style="color: #78849E">
                        <i class="mdi text-font-gray mr-auto" :class="extension(data)"></i>+info
                    </span>
                    <span v-if="isValid(data.link)" class="font-semibold text-font-gray text-md cursor-pointer mt-1"
                          @click="goLink(data.link)" style="color: #78849E">
                        <i class="mdi mdi-open-in-new text-font-gray mr-auto"></i>
                    </span>
                </span>
                <div v-if="data.file && isAudio(data.file)" class="h-12 w-full flex flex-row justify-center items-center py-2">
                    <div class=" w-4/6 flex flex-row ml-8 justify-center items-center ">
                        <i class="mdi mdi-stop text-xl cursor-pointer text-font-gray mr-2" @click="stop()"></i>
                        <i class="text-4xl cursor-pointer mdi text-font-gray" :class="{'mdi-pause': playmode, 'mdi-play': !playmode}"
                           style="transition: all 1s" @click="play()"></i>
                        <i class="mdi text-xl cursor-pointer text-font-gray ml-2" :class="{'mdi-volume-high':volumemode, 'mdi-volume-off':!volumemode}"
                           @click="mute()"></i>
                    </div>
                    <div class=" w-1/6 mt-auto flex flex-row justify-end items-center pb-3">
                        <i class="mdi mdi-thumb-up-outline text-font-gray mr-2 cursor-pointer" :class="melike(data.likes)" @click="dolike(data.id)"></i>
                        <span class="text-xs">{{ data.likes.length }}</span>
                    </div>
                </div>

                <audio :id="audioId" :src="fileRoute + data.file" controls class="hidden"></audio>
            </div>
        </div>
    </div>

</template>

<script>

    import isAudio from "@/utils/IsAudio";

    export default {
        props: ['data', 'view'],
        data(){
            return {
                playmode: false,
                volumemode: true
            }
        },
        methods: {
            isAudio(element) { return isAudio(element) },
            goLink(link) { this.$goLink(link) },
            openFile(file, type) { this.$openFile(file, type) },
            extension(elem) {
                let result = ''
                result += this.$iconByExtension(elem.file)
                return result
            },
            isValid(link) { return this.$is_url(link) },
            play() {
                if (!this.playmode) {
                    document.getElementById(this.audioId).play()
                    this.playmode = true
                }
                else {
                    document.getElementById(this.audioId).pause()
                    this.playmode = false
                }
            },
            mute() {
                if (this.volumemode) {
                    this.volumemode = false
                    document.getElementById(this.audioId).muted = true;
                }
                else {
                    this.volumemode = true
                    document.getElementById(this.audioId).muted = false;
                }
            },
            stop() {
                document.getElementById(this.audioId).load()
                this.playmode = false
            },
            dolike(id) {
                this.axios.post(process.env.VUE_APP_BACKEY_CMS_URL + 'like?resource=CommAndPodcast&id='+id, {params: state.globalParams})
                    .then(response => {
                        this.load();
                    })
            },
            melike(podcast) { return this.$meLike(podcast) }
        },
        computed: {
            fileRoute() { return this.$fileRoute },
            audioId() { return 'audio_' + this.data.id }
        }
    }
</script>